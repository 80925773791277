<template>
  <div class="bg-f">
    <!--公共头部-->
    <Header></Header>
    <!-- 用户中心头部 -->
    <user-header />
    <!-- 内容 -->
    <div class="main-content">
      <div class="container">
        <!-- <div class="my-order01">
          <span>首页</span>
        </div> -->
        <div class="accout-content clearfix" style="display:flex">
          <!-- 左边 -->
          <user-left mark="resetpassword" :userId="userId"/>
          <!-- 右边 -->
           <a-card :bordered="false" class="card-right">
             <div class="first">
                <span>{{ $t('user.current-account') }}:</span>
                <span>{{ account }}</span>
              </div>
              <div class="first">
                <span>{{ $t('user.yzm') }}:</span>
                  <input type="text" v-model="code">
                  <button  type="button" class="ant-btn"  @click="() => {
                  e => e.preventDefault()
                  sendCode()
                  }"><span>{{count}}</span></button>
             </div>
             <div class="first">
                <span>{{ $t('user.new_pass') }}:</span>
                <input type="password"  @blur="() => {
                        e => e.preventDefault()
                        Checkpwd()
                        }" v-model="pwd" :placeholder="$t('case.sensitive')" @focus="qcpwd()">
              </div>
              <div class="acone" v-show="pwdtag">{{ $t('password.incorrect') }}</div>
              <div class="first">
                <span>{{ $t('user.confirm_pass') }}:</span>
                <input type="password" @blur="() => {
                        e => e.preventDefault()
                        Checkrwd()
                        }" v-model="rwd"  @focus="qcrwd()">
              </div>
              <div class="acone" v-show="rwdtag">{{ $t('two.passwords') }}</div>
              <button class="submit" @click="regsub()">{{ $t('user.submit') }}</button>
              <div class="acone" v-show="subtag">{{ $t('correctly') }}</div>
           </a-card>
        </div>
      </div>
    </div>
    <!-- 公共尾部 -->
    <Footer></Footer>
  </div>
</template>

<style>
  .ant-card{
    width: 100%;
  }
  .ant-card-body{
    text-align: left;
  }
  .ant-table-body{
    margin-top: 10px;
  }
  .one{
    font-size: 24px;
    margin: 20px 0 0 20px;
    text-align: left;
  }
  .first{
      text-align: left;
      margin-top: 40px;
  }
  .first:nth-child(1){
    margin-top: 0;
  }
  .first>span{
      font-size: 16px;
      width: 80px;
      display: inline-block;
  }
  input[type=password]{
    width: 300px;
    border: 1px solid #787878;
    margin-left: 10px;
    height: 40px;
  }
  input[type=text]{
    width: 200px;
    border: 1px solid #787878;
    margin-left: 10px;
    height: 40px;
  }
  .ant-btn{
    width: 100px;
    height: 40px;
  }
  .acone{
      color: red;
      font-size: 14px;
      text-align: left;
      margin-left: 90px;
  }
  .submit{
      width: 392px;
      height: 40px;
      margin-top: 40px;
      border: 1px solid  #d9d9d9;
      line-height: 40px;
      background: #ff4d4f;
      color: #fff;
      font-size: 16px;
      border-radius: 4px;
  }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  name: 'UsersResetpassword',
  inject: ['reload'], // 注入依赖
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
      userId: this.$route.params.userId,
      account: localStorage.account,
      pwd: '',
      rwd: '',
      code: '',
      pwdtag: false,
      rwdtag: false,
      subtag: false
    }
  },
  computed: {
    count () {
      return this.$t('user.get_yzm')
    }
  },
   methods:{
     // 发送验证码
    sendCode(keywords){
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            }
        }, 1000)
      }
      const params = {
          account: this.account,
          type: 'resetpass',
      }
      this.$common.handlePost('/code/send', params).then(data => {
        if (data.code === 200) {
          this.$layer.alert("发送验证码成功");
        }
      })
    },
    //去除提示信息
    qcac() {
        this.tag = false
    },
    // 验证密码
    Checkpwd() {
      const FloatRegex  = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/
      if (!FloatRegex.test(this.pwd)) {
        this.pwdtag = true
        return
      }
    },
     //去除提示信息
    qcpwd() {
        this.pwdtag = false
    },
    //确认密码是否一致
    Checkrwd(){
        if (this.pwd != this.rwd){
            this.rwdtag = true
        }
    },
    //去除提示信息
    qcrwd() {
        this.rwdtag = false
    },
    //修改密码
    regsub() {
        if (this.pwd == '' || this.code == '') {
            this.subtag = true
            return 
        }
        const params = {
            confirm_password: this.rwd,
            password: this.pwd,
            code: this.code
        }
        this.$common.handlePost('/user/reset/password', params).then(data => {
        if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.reload()
        } else {
          this.$layer.alert(data.msg);
        }
      })
    }
   }
}
</script>