var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-f"},[_c('Header'),_c('user-header'),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"accout-content clearfix",staticStyle:{"display":"flex"}},[_c('user-left',{attrs:{"mark":"resetpassword","userId":_vm.userId}}),_c('a-card',{staticClass:"card-right",attrs:{"bordered":false}},[_c('div',{staticClass:"first"},[_c('span',[_vm._v(_vm._s(_vm.$t('user.current-account'))+":")]),_c('span',[_vm._v(_vm._s(_vm.account))])]),_c('div',{staticClass:"first"},[_c('span',[_vm._v(_vm._s(_vm.$t('user.yzm'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],attrs:{"type":"text"},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.code=$event.target.value}}}),_c('button',{staticClass:"ant-btn",attrs:{"type":"button"},on:{"click":function () {
                !function(e) { return e.preventDefault(); }
                _vm.sendCode()
                }}},[_c('span',[_vm._v(_vm._s(_vm.count))])])]),_c('div',{staticClass:"first"},[_c('span',[_vm._v(_vm._s(_vm.$t('user.new_pass'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pwd),expression:"pwd"}],attrs:{"type":"password","placeholder":_vm.$t('case.sensitive')},domProps:{"value":(_vm.pwd)},on:{"blur":function () {
                      !function(e) { return e.preventDefault(); }
                      _vm.Checkpwd()
                      },"focus":function($event){return _vm.qcpwd()},"input":function($event){if($event.target.composing){ return; }_vm.pwd=$event.target.value}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pwdtag),expression:"pwdtag"}],staticClass:"acone"},[_vm._v(_vm._s(_vm.$t('password.incorrect')))]),_c('div',{staticClass:"first"},[_c('span',[_vm._v(_vm._s(_vm.$t('user.confirm_pass'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rwd),expression:"rwd"}],attrs:{"type":"password"},domProps:{"value":(_vm.rwd)},on:{"blur":function () {
                      !function(e) { return e.preventDefault(); }
                      _vm.Checkrwd()
                      },"focus":function($event){return _vm.qcrwd()},"input":function($event){if($event.target.composing){ return; }_vm.rwd=$event.target.value}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.rwdtag),expression:"rwdtag"}],staticClass:"acone"},[_vm._v(_vm._s(_vm.$t('two.passwords')))]),_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.regsub()}}},[_vm._v(_vm._s(_vm.$t('user.submit')))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.subtag),expression:"subtag"}],staticClass:"acone"},[_vm._v(_vm._s(_vm.$t('correctly')))])])],1)])]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }